<template>
  <div class="order">
    <div class="order__price">
      实付金额 <b>¥{{ calculations.price }}</b>
    </div>
    <div
      :class="{
        order__btn: true,
        'order__btn--active': isCreated,
        'order__btn--inactive': !isCreated
      }"
      @click="
        () => {
          if (isCreated) {
            handleConfirmOrder()
          } else {
            Notify.warning('请求中...', {
              duration: 2000
            })
          }
        }
      "
    >
      提交订单
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref } from 'vue'
import { useCommonCartEffect } from '@/effects/cartEffects'
import { createOrder } from '@/api'
import { Notify } from '@nutui/nutui'

// TODO: 下单校验购物数量

const { calculations, productCheckedList } = useCommonCartEffect()
const isCreated = ref(true)

// 下单相关逻辑
const router = useRouter()
const store = useStore()
// 确认订单
const handleConfirmOrder = async () => {
  isCreated.value = false
  const userId = store.state.user.userInfo.id
  const orderInfoSubList = []
  for (const i in productCheckedList.value) {
    const product = productCheckedList.value[i]
    orderInfoSubList.push({
      goodsId: parseInt(product.id, 10),
      quantity: product.count
    })
  }
  try {
    const result = await createOrder({
      userId,
      orderInfoSubList,
      // TO DO : payMethod: getItem(PAYMETHOD)
      payMethod: '1'
    })
    // 后端自动移除商品
    // store.dispatch('cart/cleanCartProducts', { userId })
    const orderNum = result.orderNum
    router.push(`/Pay/${orderNum}`)
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
    isCreated.value = true
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.order {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: 0.49rem;
  line-height: 0.49rem;
  background: $bgColor;
  &__price {
    flex: 1;
    text-indent: 0.24rem;
    font-size: 0.14rem;
    color: $content-fontcolor;
    b {
      color: $hightlight-fontColor;
    }
  }
  &__btn {
    width: 0.98rem;
    color: #fff;
    text-align: center;
    font-size: 0.14rem;
    &--inactive {
      background: $btn-bgColor-Inactive;
    }
    &--active {
      background: $btn-bgColor;
    }
  }
}
.mask {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 1.56rem;
    background: #fff;
    text-align: center;
    border-radius: 0.04rem;
    &__title {
      margin: 0.24rem 0 0 0;
      line-height: 0.26rem;
      font-size: 0.18rem;
      color: #333;
    }
    &__desc {
      margin: 0.08rem 0 0 0;
      font-size: 0.14rem;
      color: #666666;
    }
    &__btns {
      display: flex;
      margin: 0.24rem 0.58rem;
    }
    &__btn {
      flex: 1;
      width: 0.8rem;
      line-height: 0.32rem;
      border-radius: 0.16rem;
      font-size: 0.14rem;
      &--first {
        margin-right: 0.12rem;
        border: 0.01rem solid #4fb0f9;
        color: #4fb0f9;
      }
      &--last {
        margin-left: 0.12rem;
        background: #4fb0f9;
        color: #fff;
      }
    }
  }
}
</style>
