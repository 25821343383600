import { computed } from 'vue'
import { useStore } from 'vuex'
// 购物车相关逻辑
export const useCommonCartEffect = () => {
  const store = useStore()
  const cartList = store.state.cart.cartList
  const changeCartItemInfo = (productId, productInfo, num) => {
    // 计算购物车数量
    // TODO: Cart
    const productList = store.state.cart.cartList?.productList || {}
    let product = productList[productId]
    if (!product) {
      productInfo.count = 0
      product = productInfo
    }
    product.count = product.count + num
    // num===0,代表将这个商品从购物车移除
    if (num === 0) {
      product.count = 0
    }
    if (product.count < 0) {
      product.count = 0
    }
    const user = store.state.user.userInfo
    store.dispatch('cart/changeCartSkuInfo', { product, user })
    // store.commit('cart/changeCartItemInfo', {
    //   productId,
    //   productInfo,
    //   num
    // })
  }

  const productList = computed(() => {
    const productList = cartList?.productList || {}
    const notEmptyProductList = {}
    for (const i in productList) {
      const product = productList[i]
      if (product.count > 0) {
        notEmptyProductList[i] = product
      }
    }
    return notEmptyProductList
  })

  const productCheckedList = computed(() => {
    const productList = cartList?.productList || {}
    const productCheckedList = {}
    for (const i in productList) {
      const product = productList[i]
      if (product.check) {
        productCheckedList[i] = product
      }
    }
    return productCheckedList
  })
  const calculations = computed(() => {
    const productList = cartList?.productList
    const result = { total: 0, price: 0, allChecked: true, checked: 0 }
    if (productList) {
      for (const i in productList) {
        const product = productList[i]
        result.total += product.count
        if (
          product.status !== '销售中' ||
          (product.status === '销售中' && product.residue <= 0)
        ) {
          product.check = false
        }
        if (product.check) {
          result.price += product.count * product.unitPrice
        }
        if (
          product.count > 0 &&
          !product.check &&
          product.status === '销售中'
        ) {
          result.allChecked = false
        }
        if (product.check) {
          result.checked++
        }
      }
    }
    result.price = result.price.toFixed(2)
    return result
  })
  const isLimit = (productId) => {
    const count = cartList?.productList?.[productId]?.count || 0
    const residue = cartList?.productList?.[productId]?.residue || 0
    const maxBuyNum = cartList?.productList?.[productId]?.maxBuyNum || 0
    return count !== 0 && (count >= maxBuyNum || count >= residue)
  }
  return {
    cartList,
    productList,
    calculations,
    changeCartItemInfo,
    isLimit,
    productCheckedList
  }
}
